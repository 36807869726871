<template>
  <div>
    <div class="page-wrapper down">
      <el-pagination
        layout="total,prev, pager, next"
        :total="tableParams.totalItems"
        :current-page="tableParams.Tpage.currentPage"
        @current-change="handleCurrentChange"
        :page-size="tableParams.Tpage.currentSize"
      ></el-pagination>
    </div>
    <Table
      :width="tableParams.tableWidth"
      :loading="tableParams.loading"
      size="small"
      border
      :columns="tableParams.columnsFormat"
      :data="tableParams.contentData"
      :no-data-text="tableParams.noDataText"
      :row-class-name="rowClassName"
      style="margin:0 auto;"
    ></Table>
    <div class="page-wrapper down">
      <el-pagination
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="tableParams.Tpage.currentPage"
        :page-sizes="tableParams.pageSizeOptions"
        :page-size="tableParams.Tpage.currentSize"
        layout="total,  prev, sizes,pager, next, jumper"
        :total="tableParams.totalItems"
      ></el-pagination>
    </div>
  </div>
</template>
<script>
export default {
  name: "listWithPage",
  props: {
    tableParams: {
      type: Object,
      required: true,
      default: function() {
        return {
          Tpage: {
            currentPage: 1,
            currentSize: 20
          },
          loading: true,
          columnsFormat: [],
          contentData: [],
          totalItems: 0,
          noDataText: "无数据",
          tableWidth: 1230,
          pageSizeOptions: [20, 50, 100, 200, 400]
        };
      }
    }
  },
  computed: {
    sortedData: function() {
      let temp = this.tivdata;
      let fun = function(a, b) {
        if (a.city == "上海市" && b.city != "上海市") {
          return -1;
        } else if (a.city != "上海市" && b.city == "上海市") {
          return 1;
        } else if (a.city == "上海市" && b.city == "上海市") {
          try {
            return (
              parseInt(a.line_name.split("号线")[0]) -
              parseInt(b.line_name.split("号线")[0])
            );
          } catch {
            return 0;
          }
        } else {
          return 0;
        }
      };
      temp.sort(fun);
      return temp;
    }
  },
  methods: {
    handleCurrentChange(value) {
      this.tableParams.Tpage.currentPage = value;
      this.$emit("handlePage", {
        currentPage: value,
        currentSize: this.tableParams.Tpage.currentSize
      });
    },
    handleSizeChange(value) {
      this.tableParams.Tpage.currentSize = value;
      this.$emit("handlePage", { currentPage: 1, currentSize: value });
    },
    rowClassName(row) {
      let nday = new Date();
      let limitday = null;
      if(row.limit_date){
        limitday = new Date(...row.limit_date.split('-'));
      }
      if (row.risk_correct_stat === "整改中" || row.correct_stat === "整改中") {
        return "demo-table-info-row";
      } else if (
        row.risk_correct_stat === "常态化管理" ||
        row.correct_stat === "常态化管理"
      ) {
        return "demo-table-error-row";
      }else if (
        limitday&&limitday>nday
      ) {
        return "demo-table-limit-row";
      }else if (
        row.if_danger === "是"
      ) {
        return "demo-table-danger-row";
      } else{
        return "";
      }
      
    },
  },
  data() {
    return {};
  }
};
</script>
<style scoped>
.page-wrapper {
  text-align: right;
  padding: 10px 0;
  margin-left:-50px;
}
.down {
  padding-right: 10px;
}
</style>