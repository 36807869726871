<template>
    <div class="outer-wrapper">
        <div class="second-wrapper">
            <label class="the-label">{{name}}：</label>
            <div class="item-wrapper">
                <div v-if="if_has_option" class="null_text">暂时没有选择条件(同一类别的选项间是“或者”的关系，不同类别间的选项是“并且”的关系)</div>
                <template v-else>
                    <Tag class="tag-style" v-for="ele in selist" v-if="ele.key_name!=='city'" @on-close="handleClose(ele)" @click.native="handleClose(ele)"
                        closable>{{ele.text}}</Tag>
                </template>
            </div>

        </div>

    </div>

</template>
<script>
    
    export default {
        name: "selectedBox",
        props: {
            "selist": {
                type: Array,
                default: []
            },
            "main_title": {
                type: String,
                required: false,
                default: "已选条件",
            },
        },
        data() {
            return {
                name: this.main_title,

            }
        },
        methods: {
            handleClose(value) {
                this.$emit("minus_f", value);
            }

        },
        computed: {
            if_has_option() {
                if (this.selist.length == 0) {
                    return true;
                } else {
                    return false;
                }
            }
        }
    }

</script>
<style scoped>

    .second-wrapper {
        padding: 10px 20px;
        min-height: 50px;
    }

    .the-label {
        color: #999;
        font-size: 14px;
        margin-right: 28px;
        float: left;
        height: 32px;
        line-height: 32px;
    }

    .tag-style {
        height: 24px;
        line-height: 24px;
        min-width: 30px;
        font-size: 13px;
        margin: 5px 3px;


    }

    .item-wrapper {
        margin: 0 0 0 100px;
        min-height: 40px;
    }

    .null_text {
        font-size: 12px;
        color: #aaa;
        padding-top: 8px;
        float:left;
    }
</style>